<template>
  <div>
		<CBreadcrumb :items="links" style="margin-top: -28px;"/>
		<CRow>
			<CCol sm="6">
				<CCard>
					<CCardHeader>
						<strong>Form Perubahan</strong> Data Pribadi Anda.
					</CCardHeader>
					<CCardBody>
						<div style="text-align: center; margin-bottom: 32px;">
							<input
									id="imgUpload"
                  hidden
									ref="avatar"
								  type="file"
								  label="Foto Profile"
									accept="image/png,image/jpg,image/jpeg"
									@change="selectAvatar"
							  />
								<h6>Foto Profile</h6>
                <img
								  style="width: 186px; height: 182px; border-radius: 100%;"
									:src="user.image ? 'https://dcvisit.lintasarta.net/api/public/avatar/' + user.image : 'https://i.pravatar.cc/150?u=' + user.id"
								  @click="imgFiles"
							  />
								<p><small>klik untuk mengubah foto.</small></p>
						</div>
            <CForm>
              <CInput
								type="text"
								label="Nama Lengkap"
								placeholder="Masukkan nama Anda.."
								v-model="user.name"
								required
							/>
							<CSelect 
								disabled
								:value.sync="user.roles[0].id"
								name="role"
								label="Hak Akses"
								:options="roles"
							/>
							<CSelect
								v-if="user.role === '3' || user.role === '5'"
								:value.sync="user.site_id"
								name="site_id"
								label="Bertugas di Data Center"
								placeholder="Pilih Data Center"
								:options="sites"
								disabled
							/>
							<CInput
                disabled
								type="email"
								label="Alamat Email (digunakan untuk login)"
								placeholder="Masukkan email perusahaan.."
								v-model="user.email"
								required
							/>
              <CSelect 
								disabled
								:value.sync="user.company_id"
								name="company_id"
								label="Nama Perusahaan"
								placeholder="Perusahaan/Instansi"
								:options="companies"
							/>
              <CInput
								type="text"
								label="Nomor Induk Pegawai"
								placeholder="Masukkan Nomor Induk Pegawai Anda.."
								v-model="user.NIK"
								required
							/>
							<CInput
								type="number"
								label="Nomor ID Card (KTP)"
								v-model="user.id_card_number"
								required
							/>
              <CInput
								type="number"
								label="Nomor Telepon"
								placeholder="Gunakan awalan 62"
								v-model="user.phone"
								required
							/>
              <CInput
								type="number"
								label="Nomor Handphone"
								placeholder="Gunakan awalan 62"
								v-model="user.mobile_phone"
								required
							/>
              <input
								hidden
								id="ktpUpload"
								ref="ktp"
								type="file"
								accept="image/png,image/jpg,image/jpeg"
								@change="selectKtp"
							/>
            </CForm>
          </CCardBody>
          <CCardFooter>
						<CButton type="submit" size="sm" color="primary" @click="updateUser"><CIcon name="cil-check-circle"/> Save</CButton>
					</CCardFooter>
        </CCard>
			</CCol>
			<CCol sm="6">
				<CCard>
					<CCardHeader>
						<strong>Foto</strong> Kartu Identitas.
					</CCardHeader>
					<CCardBody>
							<img
                v-if="!user.ktp"
                style="border: 1px solid #f4f4f4; border-radius: 6px; height: 320px; width: 100%;"
                src="@/assets/icons/ktp.png"
								@click="openKtp"
                >
              <img
                v-if="user.ktp"
                style="border: 1px solid #f4f4f4; border-radius: 6px; height: 320px; width: 100%;"
                :src="'https://dcvisit.lintasarta.net/api/public/ktp/' + user.ktp"
								@click="openKtp"
              >
							<p><small>klik untuk mengunggah foto KTP.</small></p>
					</CCardBody>
				</CCard>

				<CCard>
					<CCardHeader>
						<strong>Non-Disclosure</strong> Agreement.
					</CCardHeader>
					<CCardBody>
						<CRow>
							<CCol sm="9">
								<p v-if="user.expired_at === null">Setujui NDA</p>
								<p v-if="user.expired_at !== null">Valid Hingga<br><strong>{{formatDate(user.expired_at, 'dddd, DD MMMM YYYY HH:mm')}}</strong></p>
							</CCol>
							<CCol sm="3" style="text-align: right; margin-top: 8px;">
								<CButton v-if="user.expired_at  === null" size="sm" color="success" @click="signNda()">Baca NDA</CButton>
								<CButton v-if="user.expired_at !== null" size="sm" color="primary" :disabled="new Date(user.expired_at).getTime() >= new Date().getTime()" @click="signNda()">Perbarui NDA</CButton>
							</CCol>
						</CRow>
					</CCardBody>
				</CCard>

				<CCard>
							<CCardHeader>
								<strong>Perubahan</strong> Password.
							</CCardHeader>
							<CCardBody>
								<CForm @submit.prevent="changePassword">
									<CInput
                  	@input="checkPassword"
                  	placeholder="New Password"
                  	:type="pwdType"
                  	v-model="password"
                  	name="password"
                  	autocomplete="new-password"
                  	required
                	>
                  	<template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  	<template #append-content>
                      <span @click="showPwd"><CIcon name="cil-asterisk"/></span>
                    </template>
                	</CInput>
                	<CInput
                  	@input="checkPassword"
                  	placeholder="Confirm New Password"
                  	:type="rePwdType"
                  	v-model="password_repeat"
                  	autocomplete="new-password"
                  	class="mb-4"
                  	required
                	>
                  	<template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  	<template #append-content>
                      <span @click="showRePwd"><CIcon name="cil-asterisk"/></span>
                    </template>
                	</CInput>
                	<small>Password Must:</small>
                	<ul>
			            	<li v-bind:class="{ is_valid: contains_eight_characters }">Have at least 8 characters.</li>
			            	<li v-bind:class="{ is_valid: contains_number }">Have at least 1 number (1, 2, 3, ...).</li>
			            	<li v-bind:class="{ is_valid: contains_uppercase }">Have at least 1 uppercase letter (A, B, C, ...).</li>
			            	<li v-bind:class="{ is_valid: contains_special_character }">Have at least 1 special character (*, #, @, ...).</li>
                  	<li v-bind:class="{ is_valid: password_match }">Match repeated password.</li>
		            	</ul>
                	<br>
                	<CButton color="danger" type="button" :disabled="!valid_password" @click="logoutModal = true">Change Password</CButton>
								</CForm>
							</CCardBody>
						</CCard>
			</CCol>
		</CRow>

		<CModal 
    	:show.sync="logoutModal"
    	:centered="true"
    	title="Modal title 2"
			:closeOnBackdrop="false"
    	size="sm"
    	color="danger"
  	>
    	Password akan diubah dan Anda harus login ulang. Lanjut ?
    	<template #header>
      	<h6 class="modal-title">PERHATIAN</h6>
      	<CButtonClose @click="logoutModal = false" class="text-white"/>
    	</template>
    	<template #footer>
      	<CButton @click="logoutModal = false" color="">Batal</CButton>
      	<CButton @click="changePwd()" color="">Ubah</CButton>
    	</template>
  	</CModal>
  </div>
</template>
<script>
import http from '@/utils/http-common';
import moment from 'moment';

export default {
	name: 'RoomManagement',
	data () {
		return {
			ktpFiles: undefined,
			logoutModal: false,
			id: +localStorage.getItem('uid'),
      role: +localStorage.getItem('role'),
			user: [],
      companies: [],
			sites: [],
			roles: [],
			password: '',
      password_repeat: '',
      pwdType: 'password',
      rePwdType: 'password',
			password_match: false,
      password_length: 0,
		  contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
		  contains_special_character: false,
      valid_password: false,
			links: [
      	{
          text: 'Home',
          href: '#/'
        }, 
        {
          text: 'Pengaturan Profile'
        }
      ]
		}
	},
	methods: {
		toast(message, type) {
      this.$toast.open({
        message: message,
        type: type, // success, info, warning, error, default
        position: "top", // top, bottom, top-right, bottom-right,top-left, bottom-left
        duration: 4000,
        dismissible: true
      })
    },
		formatDate(value, format) {
      return moment(String(value)).locale('Id').format(format);
    },
		signNda() {
      this.$router.push({ path: 'profile/nda' });
    },
		showPwd() {
      if (this.pwdType === 'password') {
        this.pwdType = '';
      } else {
        this.pwdType = 'password';
      }
    },
    showRePwd() {
      if (this.rePwdType === 'password') {
        this.rePwdType = '';
      } else {
        this.rePwdType = 'password';
      }
    },

		openKtp() {
      document.getElementById('ktpUpload').click()
    },
		selectKtp() {
			let formData = new FormData();
			const maxAllowedSize = 2 * 1024 * 1024;
      if (this.$refs.ktp.files[0].size > maxAllowedSize) {
      	this.toast('Maksimal ukuran gambar 2MB', 'error')
      } else {
				formData.append('ktp', this.$refs.ktp.files[0]);

    		return http.post('/users/ktp/' + this.id, formData, {
      		headers: {
        		'Content-Type': 'multipart/form-data'
      		}
    		}).then(() => {
					this.toast('Upload KTP berhasil.', 'info')
					this.getUser(this.id)
				});
			}
    },

		imgFiles() {
      document.getElementById('imgUpload').click()
    },

		selectAvatar() {
			let formData = new FormData();
			const maxAllowedSize = 2 * 1024 * 1024;
      if (this.$refs.avatar.files[0].size > maxAllowedSize) {
      	this.toast('Maksimal ukuran gambar 2MB', 'error')
      } else {
				formData.append('avatar', this.$refs.avatar.files[0]);

    		return http.post('/users/avatar/' + this.id, formData, {
      		headers: {
        		'Content-Type': 'multipart/form-data'
      		}
    		}).then(data => {
					this.toast('Upload gambar avatar berhasil.', 'info')
					this.$store.dispatch('update', {
          	name: this.user.name,
						image: data.data.data.split('/')[5],
        	})
					this.getUser(this.id);
				});
			}
    },

		getCompanies() {
			const self = this;
			return http.get('/companies')
			.then(function (response) {
				const data = response.data.data;
				for (let i = 0; i < data.length; i++) {
					const items = {};
					items['label'] = data[i]['company_name'];
					items['value'] = data[i]['id'];
					self.companies.push(items)
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		getSites() {
			const self = this;
			return http.get('/sites')
			.then(function (response) {
				const data = response.data.data;
				for (let i = 0; i < data.length; i++) {
					const items = {};
					items['label'] = data[i]['site_name'];
					items['value'] = data[i]['id'];
					self.sites.push(items)
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		getRoles() {
			const self = this;
			return http.get('/roles')
			.then(function (response) {
				const data = response.data.roles;
				for (let i = 0; i < data.length; i++) {
					const items = {};
					items['label'] = data[i]['name'];
					items['value'] = data[i]['id'];
					self.roles.push(items)
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		updateUser() {
			let self = this;
			return http.put('/users/' + self.id, {
				name: self.user.name,
        email: self.user.email,
        status: self.user.status,
        company_id: self.user.company_id,
        role: self.role,
				NIK: self.user.NIK,
				id_card_number: self.user.id_card_number,
        phone: self.user.phone,
        mobile_phone: self.user.mobile_phone,
			})
			.then(function (response) {
				self.$store.dispatch('update', {
          name: self.user.name,
					image: self.user.image,
        }).then(() => {
					self.toast('Update profile berhasil.', 'info')
				})
			}).catch(function (error) {
				console.log(error);
			});
		},
		getUser(id) {
			let self = this;
			return http.get('/users/' + id)
				.then(function (response) {
					// console.log(response.data.user)
					self.user = response.data.user
					/* response.data.user.forEach((value, index) => {
    				self.user = value;
					}); */
				}).catch(function (error) {
					console.log(error);
				});
		},
		checkPassword() {
      this.password_length = this.password.length;
			const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
			
      if (this.password_length > 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
			}

      if (this.password.length > 0 && this.password === this.password_repeat) {
        this.password_match = true;
      } else {
        this.password_match = false;
      }
			
      this.contains_number = /\d/.test(this.password);
      this.contains_uppercase = /[A-Z]/.test(this.password);
			this.contains_special_character = format.test(this.password);

			if (this.contains_number === true &&
      this.contains_uppercase === true && 
			this.contains_special_character === true ) {
        this.password_match = true;
      } else {
        this.password_match = false;
      }
      
      if (this.password_match === true && 
        this.contains_eight_characters === true &&
				this.contains_special_character === true &&
				this.contains_uppercase === true &&
				this.contains_number === true) {
					this.valid_password = true;			
      } else {
        this.valid_password = false;
      }
    },
    checkMatch() {
      if (this.password.length > 0 && this.password === this.password_repeat) {
        this.password_match = true;
      } else {
        this.password_match = false;
      }

      if (this.password_match === true) {
				this.valid_password = true;			
      } else {
        this.valid_password = false;
      }
    },
		changePwd() {
			let self = this;
			return http.put('/users/changepassword/' + self.id, {
				password: self.password,
				cpassword: self.password_repeat,
			})
			.then(function (response) {
				self.toast('Perubahan password berhasil.', 'info')
				self.$store.dispatch('logout')
			}).catch(function (error) {
				console.log(error);
			});
		}
	},
	mounted() {
		this.getUser(this.id).then(() => {
			return this.getRoles();
		}).then(() => {
			return this.getCompanies();
		}).then(() => {
			this.getSites();
		});
	}
}
</script>
<style scoped>
ul {
	padding-left: 20px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
li { 
	margin-bottom: 8px;
	color: #ff5656;
	position: relative;
}
li:before {
  content: "";
	width: 0%; height: 2px;
	background: #2ecc71;
	position: absolute;
	left: 0; top: 50%;
	display: block;
	transition: all .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.is_valid {
  color: rgba(97, 207, 69, 0.8); 
}
.is_valid:before {
  width: 100%; 
}
@keyframes draw {
  to { stroke-dashoffset: 0; }
}
</style>
